import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
  Row,
  TooltipComponent,
  UserAvatar,
} from "../../components/Component";
import DotPagination from "../../components/pagination/DotPagination.j";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getAllUsers } from "../../redux/Action/userAction";
import { bulkActionOptions, findUpper, getScreenWidth } from "../../utils/Utils";

import clsx from "clsx";
import { toast } from "react-toastify";
import { filterRole, filterStatus } from "../pre-built/user-manage/UserData";
import { useSearchParams } from "react-router-dom/dist";
const User = () => {
  const dispatch = useDispatch();
  const { loading, user, total, error } = useSelector((state) => state?.user);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [searchParams] = useSearchParams()

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(true);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: "",
    phone: "",
    status: "Active",
  });
  const [currentPage, setCurrentPage] = useState(searchParams.get("p") ? parseInt(searchParams.get("p")) : 1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [rangeStart, setRangeStart] = useState(new Date());
  const [rangeEnd, setRangeEnd] = useState(new Date());
  const [finalDate, setFinalDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const toggleDropDown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  const { errors, register } = useForm();

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // handle datepiker logic

  const handleDatePiker = async () => {
    const outputFormat = "DD-MM-YYYY";

    if (rangeStart && rangeEnd) {
      let startDate = moment(rangeStart).format(outputFormat);
      let endDate = moment(rangeEnd).format(outputFormat);

      setFinalDate({ ...finalDate, startDate: startDate, endDate: endDate });
      setCurrentPage(1);
      setIsOpenDropDown(false);
    } else if (rangeStart && !rangeEnd) {
      toast.warning("Please choose end date");
      return 0;
    } else if (!rangeStart && !rangeEnd) {
      toast.warning("Please choose start date and end date");
      return 0;
    } else {
      toast.warning("Please choose start date");
      return 0;
    }
  };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(getScreenWidth());
    }

    const resizeListener = () => handleResize();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getAllUsers({
        page: currentPage,
        limit: itemPerPage,
        startDate: finalDate?.startDate ? finalDate?.startDate : "",
        endDate: finalDate?.endDate ? finalDate?.endDate : "",
      })
    );
    return () => { };
  }, [currentPage, itemPerPage, finalDate?.startDate, finalDate?.endDate]);

  return (
    <React.Fragment>
      <Head title="User List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Users Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {total} users.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group" style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1 d-flext justify-content-end">
                            <li>
                              {/* <UncontrolledDropdown> */}
                              <Dropdown isOpen={isOpenDropDown} toggle={toggleDropDown}>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Users</span>
                                    <div className="dropdown">
                                      <DropdownItem
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownItem>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="12">
                                        <FormGroup>
                                          <Label>View Users Within Selected Dates </Label>
                                          <div className="form-control-wrap">
                                            <div className="input-daterange date-picker-range input-group flex-column  align-items-start">
                                              <DatePicker
                                                selected={rangeStart}
                                                onChange={setRangeStart}
                                                selectsStart
                                                startDate={rangeStart}
                                                endDate={rangeEnd}
                                                wrapperClassName="start-m"
                                                className="form-control"
                                                maxDate={new Date()}
                                              />{" "}
                                              <div className="input-group-addon">
                                                <span style={{ textAlign: "center" }}>To</span>
                                              </div>
                                              <DatePicker
                                                selected={rangeEnd}
                                                onChange={setRangeEnd}
                                                startDate={rangeStart}
                                                endDate={rangeEnd}
                                                selectsEnd
                                                minDate={rangeStart}
                                                wrapperClassName="end-m"
                                                className="form-control"
                                                maxDate={new Date()}
                                                disabled={!rangeStart}
                                              />
                                            </div>
                                          </div>
                                          <div className="form-note">
                                            Date Format <code>mm/dd/yyyy</code>
                                          </div>
                                        </FormGroup>
                                      </Col>

                                      <Col size="12">
                                        <FormGroup className="form-group">
                                          <div style={{ display: "flex", columnGap: "15px" }}>
                                            <Button
                                              color="secondary"
                                              onClick={(e) => {
                                                handleDatePiker();
                                              }}
                                            >
                                              Filter Date
                                            </Button>

                                            <Button
                                              color="secondary"
                                              onClick={(e) => {
                                                setRangeStart(new Date());
                                                setRangeEnd(new Date());
                                                setFinalDate({ ...finalDate, startDate: "", endDate: "" });
                                                setCurrentPage(1);
                                                setIsOpenDropDown(false);
                                              }}
                                            >
                                              Reset Date
                                            </Button>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>
                                </DropdownMenu>
                              </Dropdown>
                              {/* </UncontrolledDropdown> */}
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : error ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span>Something went wrong...</span>
                  <span onClick={() => window.location.reload()} style={{ color: "dodgerblue", cursor: "pointer" }}>
                    try again
                  </span>
                </p>
              </div>
            ) : user?.length ? (
              <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">User</span>
                  </DataTableRow>

                  <DataTableRow size="sm">
                    <span className="sub-text">Email</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Phone</span>
                  </DataTableRow>

                  <DataTableRow size="lg">
                    <span className="sub-text">Country</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Verified</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Profile Complete</span>
                  </DataTableRow>
                  <DataTableRow>
                    <span className="sub-text">Status</span>
                  </DataTableRow>

                  <DataTableRow size="lg">
                    <span className="sub-text">Created At</span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools text-right">
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="btn btn-xs btn-outline-light btn-icon dropdown-toggle">
                        <Icon name="plus"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right className="dropdown-menu-xs">
                        <ul className="link-tidy sm no-bdr">
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="bl" />
                              <label className="custom-control-label" htmlFor="bl">
                                Balance
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="ph" />
                              <label className="custom-control-label" htmlFor="ph">
                                Phone
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="vri" />
                              <label className="custom-control-label" htmlFor="vri">
                                Verified
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input type="checkbox" className="custom-control-input form-control" id="st" />
                              <label className="custom-control-label" htmlFor="st">
                                Status
                              </label>
                            </div>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {user?.map((item) => {
                  return (
                    <DataTableItem key={item._id}>
                      <DataTableRow>
                        <Link to={`/user/${item?._id}?p=${currentPage}`}>
                          <div className="user-card">
                            {item?.profilePhoto?.url ? (
                              <UserAvatar
                                className="xs"
                                text={findUpper(item?.fullName)}
                                image={`${process.env.REACT_APP_IMG_URL}${item?.profilePhoto?.url}`}
                                imageAlt={item?.fullName}
                              ></UserAvatar>
                            ) : (
                              <UserAvatar theme="lighter" className="xs" icon={"user-alt"}></UserAvatar>
                            )}
                            <div className="user-info">
                              <span className="tb-lead">
                                {`${item?.fullName ? item?.fullName : ""}`} {item?.lastName ? item?.lastName : ""}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </DataTableRow>

                      <DataTableRow size="sm">
                        <span>{item?.email}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.countryCode ? item?.countryCode + item?.mobile : item?.mobile}</span>
                      </DataTableRow>

                      <DataTableRow size="lg">
                        <span>{item?.nationality?.translations[0]?.countryTranslation?.name}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <ul className="list-status">
                          <li>
                            <Icon className={`text-success`} name={`check-circle`}></Icon>{" "}
                            <span>
                              {item?.emailVerified && item?.mobileVerified
                                ? "Mobile, Email"
                                : item?.emailVerified
                                  ? "Email"
                                  : item?.mobileVerified
                                    ? "Mobile"
                                    : null}
                            </span>
                          </li>
                        </ul>
                      </DataTableRow>

                      <DataTableRow>
                        <span className={`tb-status text-${item?.completeProfile ? "success" : "danger"}`}>
                          {item?.completeProfile ? "Yes" : "No"}
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className={`tb-status text-${item.active ? "success" : "danger"}`}>
                          {item.active ? "Active" : "Inactive"}
                        </span>
                      </DataTableRow>

                      <DataTableRow size="lg">
                        <span>{item?.createdAt ? moment(item.createdAt)?.utc()?.format("DD MMM YY h:mm A") : ""}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                          <li
                            className="nk-tb-action-hidden"
                          // onClick={() => onEditClick(item.id)}
                          >
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-trigger btn-icon"
                              id={"edit" + item.id}
                              icon="edit-alt-fill"
                              direction="top"
                              text="Edit"
                            />
                          </li>
                          {item.status !== "Suspend" && (
                            <React.Fragment>
                              <li
                                className="nk-tb-action-hidden"
                              //  onClick={() => suspendUser(item.id)}
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"suspend" + item.id}
                                  icon="user-cross-fill"
                                  direction="top"
                                  text="Suspend"
                                />
                              </li>
                            </React.Fragment>
                          )}
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li
                                  // onClick={() => onEditClick(item.id)}
                                  >
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  {item.status !== "Suspend" && (
                                    <React.Fragment>
                                      <li className="divider"></li>
                                      <li
                                      // onClick={() => suspendUser(item.id)}
                                      >
                                        <DropdownItem
                                          tag="a"
                                          href="#suspend"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="na"></Icon>
                                          <span>Suspend User</span>
                                        </DropdownItem>
                                      </li>
                                    </React.Fragment>
                                  )}
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })}
              </DataTableBody>
            ) : !user?.length ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span>No user list was found for this date range.</span>
                  <span
                    onClick={() => {
                      setRangeStart(new Date());
                      setRangeEnd(new Date());
                      setCurrentPage(1);
                      setFinalDate({ ...finalDate, startDate: "", endDate: "" });
                    }}
                    style={{ color: "dodgerblue", cursor: "pointer" }}
                  >
                    Reset It
                  </span>
                </p>
              </div>
            ) : null}
            <div className="card-inner">
              <DotPagination
                itemPerPage={itemPerPage}
                totalItems={total}
                paginate={paginate}
                currentPage={currentPage}
                siblingCount={screenWidth < 575 ? 0 : 2}
                screenWidth={screenWidth}
              />
            </div>
          </DataTable>
        </Block>
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add User</h5>
              <div className="mt-4">
                <Form
                  className="row gy-4"
                // onSubmit={handleSubmit(onFormSubmit)}
                >
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        defaultValue={formData.balance}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={formData.phone}
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{ value: "active", label: "Active" }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form
                  className="row gy-4"
                // onSubmit={handleSubmit(onEditSubmit)}
                >
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        disabled
                        defaultValue={parseFloat(formData.balance.replace(/,/g, ""))}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={Number(formData.phone)}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{
                            value: formData.status,
                            label: formData.status,
                          }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default User;
